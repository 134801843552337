export const MEDIA_SOURCE = {
  AWS_S3: 'AWS_S3',
  IMAGE_KIT: 'IMAGE_KIT',
  LOCALHOST: 'LOCALHOST'
};

export const STATIC_MEDIA_STORE_PATH_STUB = '/static_images/ops';

export const mediaStoreBaseURL =
  process.env.STORYBOOK_PUBLIC_MEDIA_STORE_URL ||
  process.env.NEXT_PUBLIC_MEDIA_STORE_URL ||
  '';

export const staticMediaStoreBaseURL = `${mediaStoreBaseURL}${STATIC_MEDIA_STORE_PATH_STUB}`;

export const IMAGEKIT_DAM_ROOT_DIRECTORY = `/API/${process.env.NEXT_PUBLIC_APP_ENV}`;

export const BUDGET_CATEGORIES = [
  { label: 'Budget', value: 'BUDGET' },
  { label: 'Affordable', value: 'AFFORDABLE' },
  { label: 'Premium', value: 'PREMIUM' },
  { label: 'Luxury', value: 'LUXURY' }
];

export const INVOICE_QUOTE_PDF_TYPE = {
  INVOICE: 'INVOICE',
  QUOTE: 'QUOTE'
};

export const PDF_TITLE = {
  PROFORMA_INVOICE: 'PROFORMA INVOICE',
  QUOTATION: 'QUOTATION',
  TAX_INVOICE: 'TAX INVOICE'
};

export const CART_STATUS = {
  DRAFT: 'Draft',
  DISCARD: 'Discard',
  COLLABORATE: 'Collaborate',
  QUOTE: 'Quote',
  REVISE: 'Revise',
  APPROVE: 'Approve',
  REJECT: 'Reject',
  ORDER: 'Order',
  ABANDON: 'Abandon'
};

export const TOAST_MESSAGE_COLOR_CODE = {
  SUCCESS: '#CDFFCD',
  ERROR: '#F8D7DB'
};

export const API_RESPONSE_TOAST_DEFAULT_CONFIG = {
  message: '',
  show: false,
  toastColor: TOAST_MESSAGE_COLOR_CODE.ERROR
};

export const PARTNERS_DROPDOWN_LIMIT = 5000;

export const OM_CONFIG_ADMINS = JSON.parse(
  process.env.NEXT_PUBLIC_OM_CONFIG_ADMINS
);

export const orderEditWindowS3URL =
  '/configuration/order-management/orderEditWindowPostEventDate.json';
